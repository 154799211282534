import React, { useEffect, useState } from "react"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Container from "@material-ui/core/Container"
import NavigationDrawer from "./NavigationDrawer"
import { Link } from "gatsby"
import { Icon } from "@material-ui/core"
import classNames from "classnames"

const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    logoLink: {
      color: "#fff",
      textDecoration: "none",
      fontSize: 18,
      fontWeight: 700,
      textShadow: "0px 0px 5px #000",
      transition: "all 300ms",
    },
    logoLinkBottom: {
      color: '#111',
      textShadow: "none",
    },
    appBar: {
      transition: 'all 300ms',
    },
    appBarTop: {
      backgroundColor: "transparent",
      color: '#fff',
      padding: '20px 0',
    },
    appBarBottom: {
      backgroundColor: "rgba(255,255,255,0.9)",
      color: '#000',
      padding: '0',
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex"
      }
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none"
      }
    },
    list: {
      width: 250
    },
    logo: {
      height: 50,
      marginTop: 5
    },
    toolbar: {

    },
    desktopNav: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        marginLeft: theme.spacing(8),
        display: "flex",
        justifyContent: "flex-end",
        gap: "32px",
      },
    },
    desktopNavItemWrapper: {
      display: "flex",
    },
    desktopNavItem: {
      transition: 'all 300ms',
      fontSize: 15,
      textDecoration: "none",
      textTransform: "uppercase",
      // fontFamily: "Spectral SC",
      fontWeight: 600,
      border: '1px solid #eee',
      padding: theme.spacing(1),

      "&:hover": {
        backgroundColor: 'rgba(255, 255, 255, 0.2)'
      }
    },
    desktopNavItemTop: {
      color: '#fff',
    },
    desktopNavItemBottom: {
      color: '#000',
    }
  })
)

const NAV = [
  { name: "Home", href: "/", icon: null },
  { name: "Services", href: "/services", icon: null },
  { name: "About Me", href: "/about-me", icon: null },
  { name: "Contact", href: "/contact", icon: null },
]

const Header = React.memo(() => {
  const classes = useStyles()
  const [scrollIsOnTop, setScrollIsOnTop] = useState(true)

  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', () => {
        setScrollIsOnTop(window.scrollY < 20);
      });
    }
  }, [])

  const renderDrawer = (
    <NavigationDrawer>
      <List component={"div"}>
        {NAV.map(item => (
          <ListItem component={Link} to={item.href} key={item.href}>
            <ListItemIcon><Icon>{item.icon}</Icon></ListItemIcon>
            <ListItemText primary={item.name}/>
          </ListItem>
        ))}
      </List>
    </NavigationDrawer>
  )

  const renderDesktopNavItem = (item) => (
    <div className={classes.desktopNavItemWrapper} key={item.href}>
      <Link to={item.href}
            className={classNames(classes.desktopNavItem, { [classes.desktopNavItemTop]: scrollIsOnTop, [classes.desktopNavItemBottom]: !scrollIsOnTop })}
      >
        {item.name}
      </Link>
    </div>
  )

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" elevation={0}
              className={classNames(classes.appBar, { [classes.appBarTop]: scrollIsOnTop, [classes.appBarBottom]: !scrollIsOnTop })}>
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar} disableGutters>
            {renderDrawer}
            <Link to="/" className={classNames(classes.logoLink, { [classes.logoLinkBottom]: !scrollIsOnTop })}>
              McCalla Coaching
            </Link>
            <div className={classes.grow}>
              <nav className={classes.desktopNav}>
                {NAV.map(renderDesktopNavItem)}
              </nav>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
})

export default Header