import React from "react"
import Theme from "./components/Theme"
import { CssBaseline } from "@material-ui/core"

const App = ({ children }) => {
  return (
    <>
      <CssBaseline/>
      <Theme>
        {children}
      </Theme>
    </>
  )
}

export default App
