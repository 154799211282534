import React from 'react';
import App from "./src/App"
import Layout from "./src/components/Layout"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const wrapRootElement = ({ element, props}) => {
  return <App {...props}>{element}</App>;
};

export const wrapPageElement = ({ element, props}) => {
  return <Layout {...props}>{element}</Layout>;
};
