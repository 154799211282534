import React, { useState } from "react"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import { Drawer } from "@material-ui/core"

const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: (props) => props.showMenuOnDesktop ? "block" : "none"
      }
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex"
      }
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none"
      }
    },
    list: {
      width: 250
    }
  })
)

const NavigationDrawer = (props) => {
  const {
    showMenuOnDesktop = false,
    anchor = "left",
    children,
    ...rest
  } = props
  const classes = useStyles({ showMenuOnDesktop })
  const [openDrawer, setOpenDrawer] = useState(false)

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setOpenDrawer(open)
  }

  return (
    <>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon/>
      </IconButton>
      <Drawer open={openDrawer} onClose={toggleDrawer(false)} anchor={anchor} {...rest}>
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          {children}
        </div>
      </Drawer>
    </>
  )
}

export default NavigationDrawer
