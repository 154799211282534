import React from "react"
import Container from "@material-ui/core/Container"

import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import EmailIcon from "@material-ui/icons/Email"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import IconButton from "@material-ui/core/IconButton"
import { Link } from "gatsby"


const useStyles = makeStyles((theme) =>
  createStyles({
    footerContainer: {
      backgroundColor: theme.palette.grey[100],
      borderTop: "1px solid",
      borderColor: theme.palette.grey[400],
      padding: theme.spacing(2),
      marginTop: theme.spacing(12),
    },
    footer: {
      padding: theme.spacing(4, 0),

      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(8, 0),
      }
    }
  })
)

const Footer = props => {
  const classes = useStyles()
  return (
    <div className={classes.footerContainer}>
      <Container component="footer" className={classes.footer}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" component="h3">Michael McCalla Coaching</Typography>
            <Typography variant="caption">
              © Copyright {new Date().getFullYear()}, All Rights Reserved
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="E-Mail of Michael McCalla"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
              component={Link}
              to="/contact"
            >
              <EmailIcon/>
            </IconButton>
            <IconButton
              aria-label="Facebook of Michael McCalla"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <FacebookIcon/>
            </IconButton>
            <IconButton
              aria-label="Instagram of Michael McCalla"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <InstagramIcon/>
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default Footer
