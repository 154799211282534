import React  from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { deepOrange } from "@material-ui/core/colors"

// https://material.io/resources/color/#!/?view.left=0&view.right=0&primary.color=B71C1C

const Theme = (props) => {
  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: '#b71c1c',
          },
          secondary: deepOrange,
        },
        typography: {
          fontSize: 14,
          h1: {
            fontSize: 50,
          },
          h2: {
            fontSize: 40,
          },
          h3: {
            fontSize: 35,
          },
          h4: {
            fontSize: 30
          },
          h5: {
            fontSize: 25
          },
          h6: {
            fontSize: 20
          }
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
  );
};

export default Theme;
